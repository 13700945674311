define("discourse/plugins/discourse-cakeday/discourse/templates/connectors/user-custom-preferences/user-date-of-birth-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.cakeday_birthday_enabled}}
    <div class="control-group">
      <label class="control-label">{{i18n "user.date_of_birth.label"}}</label>
      <div class="controls">
        <ComboBox
          @content={{this.months}}
          @value={{this.month}}
          @valueAttribute="value"
          @valueProperty="value"
          @none="cakeday.none"
          @options={{hash clearable=true autoInsertNoneItem=false}}
          @onChange={{action (mut this.month)}}
        />
  
        <ComboBox
          @content={{this.days}}
          @value={{this.day}}
          @valueProperty={{null}}
          @nameProperty={{null}}
          @none="cakeday.none"
          @options={{hash clearable=true autoInsertNoneItem=false}}
          @onChange={{action (mut this.day)}}
        />
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "E/198lWe",
    "block": "[[[41,[30,0,[\"siteSettings\",\"cakeday_birthday_enabled\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,1],[\"user.date_of_birth.label\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@content\",\"@value\",\"@valueAttribute\",\"@valueProperty\",\"@none\",\"@options\",\"@onChange\"],[[30,0,[\"months\"]],[30,0,[\"month\"]],\"value\",\"value\",\"cakeday.none\",[28,[37,3],null,[[\"clearable\",\"autoInsertNoneItem\"],[true,false]]],[28,[37,4],[[30,0],[28,[37,5],[[30,0,[\"month\"]]],null]],null]]],null],[1,\"\\n\\n      \"],[8,[39,2],null,[[\"@content\",\"@value\",\"@valueProperty\",\"@nameProperty\",\"@none\",\"@options\",\"@onChange\"],[[30,0,[\"days\"]],[30,0,[\"day\"]],null,null,\"cakeday.none\",[28,[37,3],null,[[\"clearable\",\"autoInsertNoneItem\"],[true,false]]],[28,[37,4],[[30,0],[28,[37,5],[[30,0,[\"day\"]]],null]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"i18n\",\"combo-box\",\"hash\",\"action\",\"mut\"]]",
    "moduleName": "discourse/plugins/discourse-cakeday/discourse/templates/connectors/user-custom-preferences/user-date-of-birth-input.hbs",
    "isStrictMode": false
  });
});