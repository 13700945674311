define("discourse/plugins/discourse-cakeday/discourse/routes/cakeday-birthdays", ["exports", "@ember/service", "discourse/routes/discourse", "I18n"], function (_exports, _service, _discourse, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.service)(),
    beforeModel() {
      if (!this.siteSettings.cakeday_birthday_enabled) {
        this.router.transitionTo("unknown", window.location.pathname.replace(/^\//, ""));
      }
    },
    titleToken() {
      return _I18n.default.t("birthdays.title");
    }
  });
});