define("discourse/plugins/discourse-cakeday/discourse/templates/cakeday", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="container cakeday">
    <ul class="nav-pills">
      {{#if this.cakedayEnabled}}
        <li class="nav-item-anniversaries">
          <LinkTo @route="cakeday.anniversaries">
            {{i18n "anniversaries.title"}}
          </LinkTo>
        </li>
      {{/if}}
  
      {{#if this.birthdayEnabled}}
        <li class="nav-item-birthdays">
          <LinkTo @route="cakeday.birthdays">
            {{i18n "birthdays.title"}}
          </LinkTo>
        </li>
      {{/if}}
    </ul>
  
    {{outlet}}
  </div>
  */
  {
    "id": "Q7Nopoc5",
    "block": "[[[10,0],[14,0,\"container cakeday\"],[12],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"nav-pills\"],[12],[1,\"\\n\"],[41,[30,0,[\"cakedayEnabled\"]],[[[1,\"      \"],[10,\"li\"],[14,0,\"nav-item-anniversaries\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@route\"],[\"cakeday.anniversaries\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,2],[\"anniversaries.title\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"birthdayEnabled\"]],[[[1,\"      \"],[10,\"li\"],[14,0,\"nav-item-birthdays\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@route\"],[\"cakeday.birthdays\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,2],[\"birthdays.title\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"if\",\"link-to\",\"i18n\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-cakeday/discourse/templates/cakeday.hbs",
    "isStrictMode": false
  });
});